<template>
  <div></div>
</template>

<script>
import ImageFieldForm from "@/views/components/ImageFieldForm.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { set, sortBy, cloneDeep } from "lodash";

export default {
  name: "LobbySpecificForm",
  components: {
    ImageFieldForm,
    ValidationProvider,
    ValidationObserver,
  },

  props: {
    propData: {
      type: Object,
      required: true,
    },
    collapse: {
      type: Object,
      required: true,
    },
    images: {
      type: Array,
      default: () => [],
      required: true,
    },
  },

  data() {
    return {
      data: { ...this.propData },
      sizes: ["phone", "tablet", "laptop"],
      size: "phone",
    };
  },

  methods: {
    updateParent() {
      this.$emit("update:data", this.data);
    },

    loadLobbyImages() {
      this.$emit("loadLobbyImages");
    },

    errorInLoadImage(error) {
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: error,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },

    setPreviewImage(e) {
      // console.log(e);
    },

    onColorChange(event, target) {
      // this.data[target] = event;
      set(this.data, target, event);
      // console.log(target);
      // console.log(this.data[target]);
      // console.log(this.data.tags_menu.background_color);
      this.updateParent();
    },

    async onChangeImage(value, target) {
      if (value === "reload" && this.images?.routes?.length) {
        this.loadLobbyImages();
      } else if (value === "error") console.log("error");
      else {
        set(this.data, target, value);
        this.updateParent();
      }
      //Lodash's _.set() method is used to set the value of a property on an object in a nested manner.
    },

    onUploadedImage(data, target) {
      if (data?.files.length) {
        set(this.data, target, data.files[data.files.length - 1]);
        this.updateParent();
      }
    },
  },

  watch: {
    propData: {
      handler(newVal) {
        this.data = { ...newVal };
      },
      deep: true,
    },
  },
};
</script>
